import React, {useState} from 'react';
import {Container, Card, CardContent, makeStyles, Grid, Button} from '@material-ui/core';
// import QRCode from 'qrcode';
import { Scanner } from '@yudiel/react-qr-scanner';
import * as XLSX from 'xlsx';
// import axios from "axios";

function App() {  
  const [scanWebCam, setScanResultWebCam] =  useState([]);
  const [scanResultWebCam, setScanResultWebCam2] =  useState('');
  const [resultName, setResultName] =  useState('');
  const classes = useStyles();
  function removeDuplicates(scanWebCam) {
    return scanWebCam.filter((item,
        index) => scanWebCam.indexOf(item) === index);
  }
  const handleScanWebCam = (result) => {
    if (result){
        scanWebCam.push(result)
        setScanResultWebCam(scanWebCam);
        localStorage.setItem('result', JSON.stringify(removeDuplicates(scanWebCam)) );
        const resultCam = JSON.parse(localStorage.getItem('result'))
        const scan = resultCam.map((element, index) => {
          return <p key={index}>{element}</p>;
        });
        setScanResultWebCam2(scan)
    }
   }
  const delScanWebCam = () => {
    localStorage.setItem('result', JSON.stringify([]) );
    setScanResultWebCam2('')
  }

  function getGtin (gtin) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer 32cfc9187a4648b3b9de3e52f8e306c8c399c4ed56012b054a5f2b6373c22ab9");

    var raw = JSON.stringify({
      "gtin": gtin
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api-travel.fisolini.ru/ksr/up", requestOptions)
      .then(response => response.json())
      .then(result => setResultName(result.json()))
      .catch(error => console.log('error', error));
    const name =  JSON.parse((resultName))['data']['name']
    return (name)
    }


  const saveExcel = ()=>{
    const q = JSON.parse(localStorage.getItem('result'))
    const MYdata = q.map((element, index) => {
      const gtin = element.split("")[0].substring(3).slice(0, -15);
      const name = getGtin(gtin)
      console.log(gtin)
      console.log(name)
      return ( {"Наименование":name, "Код маркировки":element.split("")[0]});
    });
    console.log(MYdata)

    const worksheet = XLSX.utils.json_to_sheet(MYdata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "MYSavedData.xlsx");
  }
  return (
    <Container className={classes.conatiner}>
          <Card>
              <h2 className={classes.title}>Сканирование Честного знака</h2>
              <CardContent>
                  <Grid container spacing={2}>
                      {/* s */}
                      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                         <h3>Отсканируйте Qr код через камеру</h3>
                         <div >
                         <Scanner 
                          // onScan={(result) => console.log(result)}
                          onScan={(result) => handleScanWebCam(result[0]['rawValue'])}
                          formats={['data_matrix']}
                          delay={300}
                          />
                         </div>
                         
                      </Grid>
                  </Grid>
              </CardContent>
              <CardContent>
              <Grid>
                  <Button className={classes.btn} variant="contained" color="secondary" onClick={delScanWebCam}>Очистить список</Button>
                  <Button className={classes.conatinerGreen} variant="contained" color="secondary" onClick={saveExcel}>Сохранить в Excel</Button>
                  <div>
                    <p></p>
                  </div>
                  <h3>Отсканированные коды: {scanResultWebCam}</h3>

              </Grid>
              </CardContent>

          </Card>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
    conatiner: {
      marginTop: 10
    },
    conatinerGreen: {
      marginTop: 10,
      marginBottom: 20,
      marginLeft: '10px',
      backgroundColor: 'green'
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems:  'center',
      background: '#3f51b5',
      color: '#fff',
      padding: 20
    },
    btn : {
      marginTop: 10,
      marginBottom: 20
    }
}));
export default App;
